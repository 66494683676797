<template>
  <el-button
    size="mini"
    :type="status === 1 ? 'primary' : 'danger'"
    @click="handleClick"
    :loading="loading"
  >
    {{ status === 1 ? "解冻" : "冻结" }}
  </el-button>
</template>

<script>
import { frozenUser } from "@/api/nft.js";
export default {
  props: {
    status: {
      type: Number,
      default: 1,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClick() {
      const statusLabel = this.status === 1 ? "解冻" : "冻结";
      this.$alert(
        `是否将「${this.user.nickname}」状态切换为「${statusLabel}」`,
        "账号状态变更",
        {
          confirmButtonText: "确定",
          callback: (action) => {
            if (action === "confirm") {
              this.frozen();
            }
          },
        }
      );
    },
    async frozen() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const { data } = await frozenUser(this.user.user_id, this.status);
        console.log(data);
        this.loading = false;
        this.$emit("success");
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>
