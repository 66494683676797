<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="4" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="user_id"
                placeholder="请输入用户ID"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="mobile"
                placeholder="请输入手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0"
              ><el-input
                @keyup.enter.native="handleClickSearch"
                v-model="email"
                placeholder="请输入邮箱"
                size="normal"
              ></el-input
            ></el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="address" placeholder="请输入地址" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="ip" placeholder="请输入IP" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="nickname" placeholder="请输入昵称" size="normal"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-radio-group @change="handleStatusChange" v-model="status" style="margin-bottom: 10px;">
        <el-radio-button label="all">全部</el-radio-button>
        <el-radio-button :label="1">正常</el-radio-button>
        <el-radio-button :label="2">冻结中</el-radio-button>
      </el-radio-group>
    </div>
    <el-table :data="list" border stripe row-key="user_id">
      <el-table-column label="用户ID" prop="user_id"> </el-table-column>
      <el-table-column label="用户昵称" prop="nickname"></el-table-column>
      <el-table-column label="头像" prop="avatar">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.avatar"
            :src="scope.row.avatar"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.avatar]"
            style="max-width: 80px; max-height: 80px; border-radius: 50%"
          ></el-image>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="mobile"></el-table-column>
      <el-table-column label="邮箱" prop="email"></el-table-column>
      <el-table-column label="认证情况" prop="is_certified">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_certified ? 'success' : 'info'" size="normal">
            {{ scope.row.is_certified ? "已认证" : "未认证" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status_desc"></el-table-column>
      <el-table-column label="注册时间" prop="created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="注册IP" prop="created_ip"></el-table-column>
      <el-table-column label="最后登录时间" prop="latest_login_at">
        <template slot-scope="scope">
          <div>{{ scope.row.latest_login_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="最后登录IP" prop="latest_login_ip"></el-table-column>
      <el-table-column label="最后登录方式" prop="latest_login_type"></el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <FrozenButton
            :status="scope.row.status === 1 ? 2 : 1"
            :user="scope.row"
            @success="handleSuccess"
          ></FrozenButton>
          <el-button size="mini" type="primary" @click="handleQueryHoldNFTs(scope.row)">
            查看持有NFT
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="handleQueryCreateNFTs(scope.row)"
            style="margin: 8px 0"
          >
            查看铸造NFT
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="handleResetConfirm(scope.row)"
            style="margin: 8px"
          >
            重置支付密码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNFTUserList } from "@/api/nft.js";
import { setUserPayPasswordEmpty } from "@/api/user.js";
import FrozenButton from "./frozenButton.vue";
import dayjs from "dayjs";

export default {
  components: {
    FrozenButton,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      // 查询参数
      user_id: "",
      mobile: "",
      email: "",
      address: "",
      ip: "",
      status: "all",
      nickname: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.fetchUserList();
  },
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };

      this.user_id && (params.user_id = this.user_id);
      this.mobile && (params.mobile = this.mobile);
      this.email && (params.email = this.email);
      this.address && (params.user_bsv_address = this.address);
      this.ip && (params.ip = this.ip);
      this.nickname && (params.nickname = this.nickname);
      // this.address && (params.address = this.address);
      this.status !== "all" && (params.status = +this.status);

      return params;
    },
    async fetchUserList() {
      try {
        const params = this.getSearchParams();
        const { data } = await getNFTUserList(params);
        const { total = 0, list = [] } = data?.data;
        this.total = total;
        this.list = [...list];
      } catch (error) {}
    },

    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchUserList();
    },

    handleClickReset() {
      this.resetQuery();
      this.fetchUserList();
    },

    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.user_id = "";
      this.mobile = "";
      this.email = "";
      this.address = "";
      this.ip = "";
      this.address = "";
      this.nickname = "";
      this.status = "all";
    },

    handleSuccess() {
      this.fetchUserList();
    },

    handleQueryHoldNFTs(row) {
      this.$router.push("/nft_management?owner_id=" + row.user_id);
    },
    handleQueryCreateNFTs(row) {
      this.$router.push("/nft_management?creator_id=" + row.user_id);
    },
    handleResetConfirm(row) {
      this.$confirm(
        `该功能将重置用户的支付密码，该操作行为将记录在系统内，请谨慎使用！`,
        "密码重置",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        },
      )
        .then(() => {
          const parmas = {
            user_ucid: row.user_id,
          };
          this.handleResetPwd(parmas);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleResetPwd(parmas) {
      try {
        await setUserPayPasswordEmpty(parmas);
        this.$message.success("重置成功");
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchUserList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchUserList();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchUserList();
    },
  },
};
</script>
